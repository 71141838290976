<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Create New Galleryhalab')"
        >
          <b-row>
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                  md=""
                  class="d-flex justify-content-center"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      class="d-flex justify-content-center"
                    >
                      <upload
                        v-model="data.img_id"
                        @on-file-error="UpdateFileError"
                      />
                    </b-col>
                    <b-col cols="12">
                      <b-alert
                        v-if="fileErrors.length>0"
                        variant="danger"
                        show
                      >
                        <h4 class="alert-heading">
                          {{ $t('Upload Image Error') }}
                        </h4>
                        <div class="alert-body">
                          <span>
                            <ul
                              v-for="(val,index) in fileErrors"
                              :key="index"
                            >
                              <li>{{ val }}</li>
                            </ul>
                          </span>
                        </div>
                      </b-alert>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
        </b-card>
      </b-col><b-col
        cols="12"
        md="4"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
            >
              <b-button
                class="w-100 mb-1"
                variant="gradient-success"
                @click="create"
              >
                {{ $t('Create') }}
              </b-button>
              <b-button
                class="w-100"
                variant="gradient-danger"
                @click="data={},errors=[]"
              >
                {{ $t('Reset Form') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {},
  created() {},
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.data.part = 'halab'
      this.errors = []
      this.$store.dispatch('galleryhalab/Create', this.data).then(() => {
        this.data = {}
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
